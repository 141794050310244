import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { REGISTER_USER } from '../../../apollo/mutations';
import Alert from '../../layout/Alert';
import context from '../../../context/context';

const Verification = () => {
  const Context = useContext(context);
  const { codeSent, authSuccess } = Context;

  const [registerUser, { loading, data, error }] = useMutation(REGISTER_USER);

  const [code, setCode] = useState('');

  const manageSubmit = e => {
    e.preventDefault();

    registerUser({ variables: { emailCode: code } });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      localStorage.setItem('token', data.registerUser.token);

      authSuccess(data.registerUser.user);
    }

    // eslint-disable-next-line
  }, [data, loading, error]);

  if (!codeSent)
    return (
      <div className='container'>
        <h3 className='form-header'>
          It looks like you do not have access to this page.
        </h3>
      </div>
    );

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Verification</h1>
        <div className='form-item'>
          <p style={{ maxWidth: '22.25rem' }}>
            Thank you for registering! Please check your inbox for a
            verification code from us and enter it below.
          </p>
        </div>
        {error &&
          !loading &&
          !data &&
          error.graphQLErrors.map((err, index) => (
            <Alert key={index} type='error' msg={err.message} />
          ))}
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Enter Code'
            onChange={e => setCode(e.target.value)}
          />
        </div>
        <div className='form-item'>
          {!loading ? (
            <input type='submit' className='form-submit' value='Verify' />
          ) : (
            <input
              type='submit'
              className='form-submit preloader'
              value=''
              disabled
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default Verification;
