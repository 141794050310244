import React from 'react'

const StyleButton = props => {
  const { style, label, active } = props

  const onToggle = e => {
    e.preventDefault()
    props.onToggle(style)
  }

  let className = 'style-btn'
  if (active) className += ' active-style'

  return (
    <span className={className} onMouseDown={onToggle}>
      {label}
    </span>
  )
}

export default StyleButton
