import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import State from './context/State'

// Apollo
import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { ApolloClient } from 'apollo-client'

const apolloCache = new InMemoryCache()

const uploadLink = createUploadLink({
  uri: process.env.NODE_ENV === 'production' ? 'https://texenergy.appspot.com/graphql' : 'http://localhost:8080/graphql'
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : ''
    }
  }
})

const client = new ApolloClient({
  cache: apolloCache,
  link: authLink.concat(uploadLink)
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <State>
      <App />
    </State>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
