import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import RegisteredHomes from './RegisteredHomes';
import Context from '../../../context/context';

const Claim = () => {
  const context = useContext(Context);
  const { authorized } = context;

  if (!authorized)
    return (
      <div className='container'>
        <h2
          style={{
            textAlign: 'center',
            color: '#185493',
            padding: '1rem',
            marginBottom: '15rem'
          }}
        >
          You need to be logged in to view this page.
        </h2>
      </div>
    );

  return (
    <div className='container'>
      <div className='claim-top'>
        <div className='claim-top-left'>
          <h2 className='claim-header'>My Registered Homes</h2>
        </div>
        <div className='claim-top-right'>
          <NavLink exact to='/claim/new' className='claim-new-home-btn'>
            <i className='fas fa-plus'></i> New Home
          </NavLink>
        </div>
      </div>
      <div className='claim-bottom'>
        <RegisteredHomes />
      </div>
    </div>
  );
};

export default Claim;
