import React, { useContext, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import './App.css'
import { useQuery } from '@apollo/react-hooks'
import { AUTH_USER } from './apollo/queries'
import Context from './context/context'

// Layout
import SocialMediaNav from './components/layout/navbar/SocialMediaNav'
import NavBar from './components/layout/navbar/NavBar'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'

//pages/public
import Home from './components/pages/public/Home'
import Faq from './components/pages/public/Faq'
import Contact from './components/pages/public/Contact'
import Register from './components/pages/public/Register'
import Login from './components/pages/public/Login'
import ForgotPassword from './components/pages/public/ForgotPassword'
import Terms from './components/pages/public/Terms'

//pages/private
import Claim from './components/pages/private/Claim'
import NewHome from './components/pages/private/NewHome'
import NewClaim from './components/pages/private/NewClaim'
import Verification from './components/pages/private/Verification'
import ResetPassword from './components/pages/private/ResetPassword'

import Logo from './images/efficiency_promise_logo.png'
import Loader from './images/loader.svg'

const App = () => {
  const context = useContext(Context)
  const { authSuccess, authError, authorized, codeSent } = context

  const { data, loading, error } = useQuery(AUTH_USER)

  useEffect(() => {
    if (data && data.authUser !== null && !loading && !error)
      authSuccess(data.authUser)

    if (!data && !loading && error) authError(error.graphQLErrors)
    // eslint-disable-next-line
  }, [data, loading, error])

  if (loading && localStorage.token)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <div>
          <div>
            <img src={Logo} alt='Efficiency Promise Logo' />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Loader} alt='Loading...' width='50' />
          </div>
          <div>
            <h3 className='form-header'>Logging you in...Please wait.</h3>
          </div>
        </div>
      </div>
    )

  return (
    <Router>
      <SocialMediaNav />
      <NavBar />
      <Header />
      <main>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/claim' component={Claim} />
          <Route exact path='/register' component={Register}>
            {codeSent && !authorized && (
              <Redirect exact to='/register/verify' />
            )}
          </Route>
          <Route exact path='/login' component={Login}>
            {authorized && <Redirect exact to='/claim' />}
          </Route>
          <Route exact path='/register/verify' component={Verification}>
            {authorized && <Redirect exact to='/claim' />}
          </Route>
          <Route exact path='/claim/new' component={NewHome} />
          <Route exact path='/claim/:name' component={NewClaim} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/faq' component={Faq} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/forgot-password/:id' component={ResetPassword} />
          <Route exact path='/terms' component={Terms} />
        </Switch>
      </main>
      <Footer />
    </Router>
  )
}

export default App
