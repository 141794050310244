import React, { useReducer } from 'react'
import Context from './context'
import Reducer from './reducer'
import {
  AUTH_SUCCESS,
  AUTH_ERROR,
  CODE_SUCCESS,
  CODE_ERROR,
  LOGOUT_USER,
  REFETCH_HOMES,
  SET_CURRENT_HOME
} from './types'

const State = props => {
  const initialState = {
    token: localStorage.token,
    user: null,
    authorized: false,
    error: null,
    refetchHomes: false,
    currentHome: null,
    codeSent: false
  }

  const [state, dispatch] = useReducer(Reducer, initialState)

  const authSuccess = data => {
    dispatch({
      type: AUTH_SUCCESS,
      payload: data
    })
  }

  const authError = error => {
    dispatch({
      type: AUTH_ERROR,
      payload: error
    })
  }

  const codeSuccess = () => {
    dispatch({
      type: CODE_SUCCESS
    })
  }

  const codeError = error => {
    dispatch({
      type: CODE_ERROR
    })
  }

  const logout = () => {
    dispatch({
      type: LOGOUT_USER
    })
  }

  const refetchHomes = bool => {
    dispatch({
      type: REFETCH_HOMES,
      payload: bool
    })
  }

  const setCurrentHome = home => {
    dispatch({
      type: SET_CURRENT_HOME,
      payload: home
    })
  }

  return (
    <Context.Provider
      value={{
        user: state.user,
        authorized: state.authorized,
        error: state.error,
        refetchHomesState: state.refetchHomes,
        currentHome: state.currentHome,
        codeSent: state.codeSent,
        authSuccess,
        authError,
        logout,
        refetchHomes,
        setCurrentHome,
        codeSuccess,
        codeError
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default State
