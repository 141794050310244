import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { SEND_PASSWORD_LINK } from '../../../apollo/mutations'
import Alert from '../../layout/Alert'
import ReCAPTCHA from 'react-google-recaptcha'

const ForgotPassword = () => {
  const [sendPasswordLink, { loading, data, error }] = useMutation(
    SEND_PASSWORD_LINK
  )
  const recaptchaRef = useRef()

  const [email, setEmail] = useState('')

  const manageSubmit = e => {
    e.preventDefault()

    recaptchaRef.current.execute()

    sendPasswordLink({ variables: { email } })
  }

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Forgot Password</h1>
        <div className='form-item'>
          <p>
            Enter your email and we will send you a link to reset your password.
          </p>
        </div>
        <div>
          {error &&
            error.graphQLErrors.map((err, index) => (
              <Alert key={index} type='error' msg={err.message} />
            ))}
        </div>
        <div className='form-item'>
          {data && (
            <Alert
              type='success'
              msg='We have sent a password reset link to your email. Please check your inbox and/or spam folder.'
            />
          )}
        </div>
        <div className='form-item'>
          <input
            type='text'
            name='email'
            placeholder='Email'
            className='form-input'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey='6LfCXsUUAAAAAKKb2Lt76ICJRrzFgS_b2_FolcnF'
        />
        <div className='form-item'>
          {loading ? (
            <input
              type='submit'
              value=''
              className='form-submit preloader'
              disabled
            />
          ) : (
            <input type='submit' value='Send Link' className='form-submit' />
          )}
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
