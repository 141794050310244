import { gql } from 'apollo-boost'

export const AUTH_USER = gql`
  query AuthUser {
    authUser {
      name
      email
      admin
    }
  }
`

export const GET_REGISTERED_HOMES = gql`
  query GetRegisteredHomes {
    getRegisteredHomes {
      id
      communityName
      street
      zip
      phone
      email
      certificateNumber
      closingDate
      agreeToTerms
      claimSubmitted
    }
  }
`

export const GET_RESET_USER = gql`
  query GetResetUser($resetId: String!) {
    getResetUser(resetId: $resetId) {
      requestedReset
    }
  }
`

export const GET_CONTENT = gql`
  query GetContent($name: ID!) {
    getContent(name: $name)
  }
`
