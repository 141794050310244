import React, { useEffect, useRef, Fragment } from 'react'
import { Editor, RichUtils } from 'draft-js'
import InlineStyle from './InlineStyle'
import BlockStyle from './BlockStyle'

const ContentEditor = ({ editorState, setEditorState, showEditor }) => {
  const editor = useRef(null)

  const focusEditor = () => {
    editor.current.focus()
  }

  useEffect(() => {
    focusEditor()
  }, [])

  const manageChange = editorState => {
    setEditorState(editorState)
  }

  const toggleInlineStyle = inlineStyle => {
    manageChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const toggleBlockStyle = blockType => {
    manageChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const getBlockStyle = block => {
    switch (block.getType()) {
      case 'blockquote':
        return 'Richeditor-blockquote'
      default:
        return null
    }
  }

  return (
    <div className='editor' onClick={focusEditor}>
      {showEditor && (
        <Fragment>
          <BlockStyle editorState={editorState} onToggle={toggleBlockStyle} />
          <InlineStyle editorState={editorState} onToggle={toggleInlineStyle} />
        </Fragment>
      )}
      <Editor
        ref={editor}
        editorState={editorState}
        onChange={manageChange}
        getBlockStyle={getBlockStyle}
        readOnly={showEditor ? false : true}
      />
    </div>
  )
}

export default ContentEditor
