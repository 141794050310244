import React, { useState, useContext, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { NEW_CLAIM } from '../../../apollo/mutations';
import Context from '../../../context/context';
import Alert from '../../layout/Alert';
import KWhEntry from './KWhEntry';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const NewClaim = () => {
  const context = useContext(Context);
  const { currentHome, refetchHomes } = context;

  const [newClaim, { data, loading, error }] = useMutation(NEW_CLAIM);

  const [claimInfo, setClaimInfo] = useState({
    files: useRef(null),
    claimStart: '',
    claimEnd: '',
    monthInt: 0,
    kWhEntries: {},
  });

  const { files, claimStart, claimEnd, monthInt, kWhEntries } = claimInfo;

  useEffect(() => {
    if (claimStart !== '') {
      let int = monthInt;

      const obj = {};

      for (let i = 1; i <= MONTHS.length; i++) {
        obj[`month_${i}`] = MONTHS[int];

        int === 11 ? (int = 0) : (int = int + 1);
      }

      setClaimInfo({
        ...claimInfo,
        kWhEntries: {
          ...kWhEntries,
          month_1: obj.month_1,
          month_2: obj.month_2,
          month_3: obj.month_3,
          month_4: obj.month_4,
          month_5: obj.month_5,
          month_6: obj.month_6,
          month_7: obj.month_7,
          month_8: obj.month_8,
          month_9: obj.month_9,
          month_10: obj.month_10,
          month_11: obj.month_11,
          month_12: obj.month_12,
        },
      });
    }
    // eslint-disable-next-line
  }, [claimStart]);

  const manageSubmit = e => {
    e.preventDefault();

    newClaim({
      variables: {
        relatedHomeId: currentHome.id,
        claimStart,
        claimEnd,
        files: files.current.files,
        kWhEntries,
      },
    });
  };

  if (currentHome === null)
    return (
      <div className='container'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '.5rem',
          }}>
          <h3 style={{ padding: '.5rem', color: '#185493' }}>Whoops! Looks like we were unable to process your request.</h3>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '.5rem',
          }}>
          <NavLink exact to='/claim' className='return-link' style={{ marginBottom: '.5rem' }}>
            Return to Claims
          </NavLink>
        </div>
      </div>
    );

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Send a Claim</h1>
        <div className='form-item'>
          <label htmlFor='bills' style={{ maxWidth: '20rem' }}>
            Upload 1 file consisting of 12 months (365 days minimum or claim will be rejected) of consecutive energy bills. Make sure claim period
            start and end aligns with your submitted bills.
          </label>
        </div>
        <div className='form-item'>
          <input type='file' name='files' className='form-file-input' ref={files} accept='image/*,.pdf,.doc,.docx' multiple />
        </div>
        <div className='form-item'>
          <label htmlFor='claimStart'>Claim Period Start:</label>
        </div>
        <div className='form-item'>
          <input
            type='date'
            className='form-input'
            name='claimStart'
            value={claimStart}
            onChange={e => {
              let date = new Date(e.target.value);
              date = date.setDate(date.getDate() + 1);
              setClaimInfo({ ...claimInfo, claimStart: e.target.value, monthInt: new Date(date).getMonth() });
            }}
          />
        </div>
        <div className='form-item'>
          <label htmlFor='claimEnd'>Claim Period End:</label>
        </div>
        <div className='form-item'>
          <input
            type='date'
            className='form-input'
            name='claimEnd'
            value={claimEnd}
            onChange={e => setClaimInfo({ ...claimInfo, claimEnd: e.target.value })}
          />
        </div>
        <div className='form-item'>
          <label htmlFor='kWhEntries' style={{ maxWidth: '20rem' }}>
            Enter 12 months of kWh and propane or natural gas gallons aligning with your submitted bills.
          </label>
        </div>
        {MONTHS.map((_, index) => (
          <KWhEntry
            key={index + 1}
            monthNum={`month_${index + 1}`}
            kWhNum={`kWh_${index + 1}`}
            galNum={`gal_${index + 1}`}
            months={MONTHS}
            kWhEntries={kWhEntries}
            claimInfo={claimInfo}
            setClaimInfo={setClaimInfo}
          />
        ))}
        {data && !loading && !error && <Alert msg='Your claim has been successfully submitted.' type='success' />}
        {error && !data && !loading && error.graphQLErrors.map((err, index) => <Alert key={index} msg={err.message} type='error' />)}
        <div className='form-item'>
          {!loading ? (
            <input type='submit' className='form-submit' hidden={data && !error ? true : false} />
          ) : (
            <input type='submit' className='form-submit preloader' value='' disabled />
          )}
        </div>
        <div className='form-item'>
          <NavLink exact to='/claim' className='return-link' onClick={() => refetchHomes(true)}>
            Return
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export default NewClaim;
