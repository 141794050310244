import {
  AUTH_SUCCESS,
  AUTH_ERROR,
  CODE_SUCCESS,
  CODE_ERROR,
  LOGOUT_USER,
  REFETCH_HOMES,
  SET_CURRENT_HOME
} from './types'

export default (state, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        token: localStorage.token,
        user: action.payload.user ? action.payload.user : action.payload,
        authorized: true,
        codeSent: false
      }
    case AUTH_ERROR:
    case CODE_ERROR:
      localStorage.removeItem('token')
      return {
        ...state,
        user: null,
        authorized: false,
        error: action.payload,
        codeSent: false
      }
    case CODE_SUCCESS:
      return {
        ...state,
        codeSent: true
      }
    case LOGOUT_USER:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        user: null,
        authorized: false,
        error: null,
        codeSent: false,
        refetchHomes: true
      }
    case REFETCH_HOMES:
      return {
        ...state,
        refetchHomes: action.payload
      }
    case SET_CURRENT_HOME:
      return {
        ...state,
        currentHome: action.payload
      }
    default:
      return state
  }
}
