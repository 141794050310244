import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_REGISTERED_HOMES } from '../../../apollo/queries';
import Loader from '../../../images/loader.svg';
import Context from '../../../context/context';

const RegisteredHomes = () => {
  const context = useContext(Context);
  const { refetchHomes, refetchHomesState, setCurrentHome } = context;

  const { data, loading, error, refetch } = useQuery(GET_REGISTERED_HOMES);

  useEffect(() => {
    if (refetchHomesState) {
      refetch();

      refetchHomes(false);
    }

    // eslint-disable-next-line
  }, [refetchHomesState]);

  if (data && data.getRegisteredHomes.length === 0 && !loading & !error && !refetchHomesState)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <h3>You currently do not have any homes registered.</h3>
      </div>
    );

  return (
    <div className='registered-homes-container'>
      <div>
        {data && !loading && !error && !refetchHomesState ? (
          data.getRegisteredHomes.map(home => (
            <div key={home.id} className='registered-homes-grid'>
              <div className='registered-homes-col'>
                <div style={{ padding: '.25rem' }}>
                  <h4 className='registered-homes-header'>{home.communityName}</h4>
                </div>
                <div style={{ padding: '.25rem' }}>
                  <p>
                    <strong>Zip Code:</strong> {home.zip}
                  </p>
                </div>
              </div>
              <div className='registered-homes-col'>
                <div style={{ padding: '.25rem' }}>
                  <p>
                    <strong>Certification No.</strong> {home.certificateNumber}
                  </p>
                </div>
                <div style={{ padding: '.25rem' }}>
                  <p>
                    <strong>Closing Date:</strong> {home.closingDate}
                  </p>
                </div>
              </div>
              <div className='registered-homes-col'>
                <div
                  style={{
                    padding: '.25rem',
                  }}>
                  <NavLink
                    exact
                    to={`/claim/${home.communityName.replace(/\s/g, '').toLowerCase()}`}
                    className='claim-btn'
                    onClick={() => setCurrentHome(home)}>
                    Claim
                  </NavLink>
                  {/* {!home.claimSubmitted ? (
                    <NavLink
                      exact
                      to={`/claim/${home.communityName
                        .replace(/\s/g, '')
                        .toLowerCase()}`}
                      className='claim-btn'
                      onClick={() => setCurrentHome(home)}
                    >
                      Claim
                    </NavLink>
                  ) : (
                    <i className='fas fa-check' style={{ color: '#28a745' }}>
                      <span style={{ paddingLeft: '.25rem' }}>Claim Sent</span>
                    </i>
                  )} */}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Loader} alt='Preloader' width='100' />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredHomes;
