import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { REGISTER_HOME } from '../../../apollo/mutations'
import Alert from '../../layout/Alert'
import Context from '../../../context/context'

export const NewHome = () => {
  const context = useContext(Context)
  const { authorized, refetchHomes } = context

  const [registerHome, { data, loading, error }] = useMutation(REGISTER_HOME)

  const [homeInfo, setHomeInfo] = useState({
    communityName: '',
    street: '',
    city: '',
    zip: '',
    phone: '',
    certificateNumber: '',
    closingDate: '',
    agreeToTerms: false
  })

  const {
    communityName,
    street,
    city,
    zip,
    phone,
    certificateNumber,
    closingDate,
    agreeToTerms
  } = homeInfo

  const manageInput = e => {
    setHomeInfo({
      ...homeInfo,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  const manageSubmit = e => {
    e.preventDefault()

    registerHome({
      variables: {
        communityName,
        street,
        city,
        zip,
        phone,
        certificateNumber,
        closingDate,
        agreeToTerms:
          typeof agreeToTerms === 'string' ? agreeToTerms : String(agreeToTerms)
      }
    })

    refetchHomes(true)
  }

  if (!authorized)
    return (
      <div className='container'>
        <h2
          style={{
            textAlign: 'center',
            color: '#185493',
            padding: '1rem',
            marginBottom: '15rem'
          }}
        >
          You need to be logged in to view this page.
        </h2>
      </div>
    )

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Register New Home</h1>
        {data && (
          <Alert
            msg={`${data.registerHome.communityName} has successfully been registered.`}
            type={'success'}
          />
        )}
        {error &&
          error.graphQLErrors.map((err, index) => (
            <Alert key={index} msg={err.message} type={'error'} />
          ))}
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Community Name'
            name='communityName'
            value={communityName}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Street Address'
            name='street'
            value={street}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='City'
            name='city'
            value={city}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Zip Code'
            name='zip'
            value={zip}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Phone'
            name='phone'
            value={phone}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            className='form-input'
            placeholder='Certificate Number'
            name='certificateNumber'
            value={certificateNumber}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <label className='register-form-label'>Closing Date:</label>
        </div>
        <div className='form-item'>
          <input
            type='date'
            className='form-input'
            name='closingDate'
            value={closingDate}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='checkbox'
            name='agreeToTerms'
            checked={agreeToTerms}
            onChange={manageInput}
          />
          <label htmlFor='terms' style={{ paddingLeft: '.5rem' }}>
            I agree to the{' '}
            <NavLink
              to='/terms'
              target='_blank'
              rel='noopener noreferrer'
              className='terms-link'
            >
              terms & conditions
            </NavLink>
            .
          </label>
        </div>
        <div className='form-item'>
          {!loading ? (
            <input type='submit' className='form-submit' value='Submit' />
          ) : (
            <input
              type='submit'
              className='form-submit preloader'
              value=''
              disabled
            />
          )}
        </div>
        <div className='form-item'>
          <NavLink exact to='/claim' className='return-link'>
            Return
          </NavLink>
        </div>
      </form>
    </div>
  )
}

export default NewHome
