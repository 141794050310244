import React, { Fragment, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Context from '../../context/context'

const Footer = () => {
  const context = useContext(Context)
  const { authorized, logout } = context

  return (
    <footer>
      <div className='footer-div'>
        <ul className='footer-nav-list'>
          <li className='footer-nav-item'>
            <NavLink
              exact
              to='/'
              activeClassName=''
              className='footer-nav-link'
            >
              HOME
            </NavLink>
          </li>
          <li className='footer-nav-item pipe'>|</li>
          <li className='footer-nav-item'>
            <NavLink
              exact
              to='/faq'
              activeClassName=''
              className='footer-nav-link'
            >
              FAQ
            </NavLink>
          </li>
          <li className='footer-nav-item pipe'>|</li>
          <li className='footer-nav-item'>
            <NavLink
              exact
              to='/contact'
              activeClassName=''
              className='footer-nav-link'
            >
              CONTACT
            </NavLink>
          </li>
          {authorized ? (
            <Fragment>
              <li className='footer-nav-item pipe'>|</li>
              <li className='footer-nav-item'>
                <NavLink
                  exact
                  to='/claim'
                  activeClassName=''
                  className='footer-nav-link'
                >
                  CLAIM
                </NavLink>
              </li>
              <li className='footer-nav-item pipe'>|</li>
              <li className='footer-nav-item'>
                <NavLink
                  exact
                  to='/login'
                  className='footer-nav-link'
                  onClick={logout}
                >
                  LOGOUT
                </NavLink>
              </li>
            </Fragment>
          ) : (
            <Fragment>
              <li className='footer-nav-item pipe'>|</li>
              <li className='footer-nav-item'>
                <NavLink
                  exact
                  to='/register'
                  activeClassName=''
                  className='footer-nav-link'
                >
                  REGISTER
                </NavLink>
              </li>
              <li className='footer-nav-item pipe'>|</li>
              <li className='footer-nav-item'>
                <NavLink
                  exact
                  to='/login'
                  activeClassName=''
                  className='footer-nav-link'
                >
                  LOGIN
                </NavLink>
              </li>
            </Fragment>
          )}
        </ul>
        <div className='copyright-wrapper'>
          <p className='copyright-text'>
            &copy; 2020 Efficiency Promise. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
