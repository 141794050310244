import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const MobileNav = ({ setShowMobileNav, authorized, logout }) => {
  return (
    <ul className='mobile-nav-list'>
      <li className='mobile-nav-item'>
        <NavLink
          exact
          to='/'
          activeClassName=''
          className='mobile-nav-link'
          onClick={() => setShowMobileNav(false)}
        >
          HOME
        </NavLink>
      </li>
      <li className='mobile-nav-item'>
        <NavLink
          exact
          to='/faq'
          activeClassName=''
          className='mobile-nav-link'
          onClick={() => setShowMobileNav(false)}
        >
          FAQ
        </NavLink>
      </li>
      <li className='mobile-nav-item'>
        <NavLink
          exact
          to='/contact'
          activeClassName=''
          className='mobile-nav-link'
          onClick={() => setShowMobileNav(false)}
        >
          CONTACT
        </NavLink>
      </li>
      {authorized ? (
        <Fragment>
          <li className='mobile-nav-item'>
            <NavLink
              to='/claim'
              activeClassName=''
              className='mobile-nav-link'
              onClick={() => setShowMobileNav(false)}
            >
              CLAIM
            </NavLink>
          </li>
          <li className='mobile-nav-item'>
            <NavLink
              to='/login'
              className='mobile-nav-link'
              onClick={() => {
                setShowMobileNav(false)
                logout()
              }}
            >
              LOGOUT
            </NavLink>
          </li>
        </Fragment>
      ) : (
        <Fragment>
          <li className='mobile-nav-item'>
            <NavLink
              exact
              to='/register'
              activeClassName=''
              className='mobile-nav-link'
              onClick={() => setShowMobileNav(false)}
            >
              REGISTER
            </NavLink>
          </li>
          <li className='mobile-nav-item'>
            <NavLink
              exact
              to='/login'
              activeClassName=''
              className='mobile-nav-link'
              onClick={() => setShowMobileNav(false)}
            >
              LOGIN
            </NavLink>
          </li>
        </Fragment>
      )}
    </ul>
  )
}

MobileNav.propTypes = {
  setShowMobileNav: PropTypes.func.isRequired,
  authorized: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
}

export default MobileNav
