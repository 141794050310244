import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SEND_MESSAGE } from '../../../apollo/mutations';
import Alert from '../../layout/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [sendMessage, { loading, data, error }] = useMutation(SEND_MESSAGE);
  const recaptchaRef = useRef();

  const [msgInfo, setMsgInfo] = useState({
    name: '',
    email: '',
    message: ''
  });

  const { name, email, message } = msgInfo;

  const manageInput = e => {
    setMsgInfo({
      ...msgInfo,
      [e.target.name]: e.target.value
    });
  };

  const manageSubmit = e => {
    e.preventDefault();

    recaptchaRef.current.execute();

    sendMessage({ variables: { name, email, message } });
  };

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Send Us a Message</h1>
        {error &&
          !loading &&
          !data &&
          error.graphQLErrors.map((err, index) => (
            <Alert key={index} type='error' msg={err.message} />
          ))}
        {data && !loading && !error && (
          <Alert
            type='success'
            msg='Your message has successfully been sent. We will reach out to you as soon as possible.'
          />
        )}
        <div className='form-item'>
          <input
            type='text'
            name='name'
            placeholder='Name'
            className='form-input'
            value={name}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='text'
            name='email'
            placeholder='Email'
            className='form-input'
            value={email}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <textarea
            name='message'
            cols='30'
            rows='10'
            className='form-textarea'
            placeholder='Message'
            value={message}
            onChange={manageInput}
          ></textarea>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey='6LfCXsUUAAAAAKKb2Lt76ICJRrzFgS_b2_FolcnF'
        />
        <div className='form-item'>
          {loading ? (
            <input
              type='submit'
              value=''
              class='form-submit preloader'
              disabled
            />
          ) : (
            <input type='submit' value='Send' className='form-submit' />
          )}
        </div>
      </form>
    </div>
  );
};

export default Contact;
