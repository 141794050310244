import React from 'react'

const SocialMediaNav = () => {
  return (
    <div className='social-media-nav'>
      <ul className='social-media-nav-list'>
        <li className='social-media-nav-item'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.facebook.com/pages/category/Local-Service/TexEnergy-Solutions-US-EcoLogic-197136849088/'
            className='social-media-nav-link'
          >
            <i className='fab fa-facebook-square'></i>
          </a>
        </li>
        <li className='social-media-nav-item'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.linkedin.com/company/texenergy-solutions/'
            className='social-media-nav-link'
          >
            <i className='fab fa-linkedin'></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SocialMediaNav
