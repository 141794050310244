import React, { useState, Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_RESET_USER } from '../../../apollo/queries'
import { RESET_PASSWORD } from '../../../apollo/mutations'
import Loader from '../../../images/loader.svg'
import Alert from '../../layout/Alert'
import { NavLink } from 'react-router-dom'

const ResetPassword = props => {
  const { loading, data, error } = useQuery(GET_RESET_USER, {
    variables: { resetId: props.match.params.id }
  })

  const [
    resetPassword,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(RESET_PASSWORD)

  const [userInfo, setUserInfo] = useState({
    resetId: props.match.params.id,
    password: '',
    confirmPassword: ''
  })

  const { resetId, password, confirmPassword } = userInfo

  const manageInput = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    })
  }

  const manageSubmit = e => {
    e.preventDefault()

    resetPassword({ variables: { resetId, password, confirmPassword } })
  }

  if (loading)
    return (
      <div className='container'>
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}
        >
          <img src={Loader} alt='Loading...' width='50' />
        </div>
      </div>
    )

  if ((data && data.getResetUser === null) || error)
    return (
      <div className='container'>
        <h3 className='form-header'>
          This page does not exist or has expired.
        </h3>
      </div>
    )

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Reset Password</h1>
        {mutationData && !mutationLoading && (
          <Alert
            type='success'
            msg='Your password has been successfully reset.'
          />
        )}
        {mutationError &&
          !mutationLoading &&
          mutationError.graphQLErrors.map((err, index) => (
            <Alert key={index} type='error' msg={err.message} />
          ))}
        {mutationData ? (
          <div className='form-item'>
            <NavLink exact to='/login' className='login-link'>
              Click here to login.
            </NavLink>
          </div>
        ) : (
          <Fragment>
            <div className='form-item'>
              <input
                type='password'
                name='password'
                placeholder='New Password'
                className='form-input'
                value={password}
                onChange={manageInput}
              />
            </div>
            <div className='form-item'>
              <input
                type='password'
                name='confirmPassword'
                placeholder='Confirm New Password'
                className='form-input'
                value={confirmPassword}
                onChange={manageInput}
              />
            </div>
            <div className='form-item'>
              {mutationLoading ? (
                <input
                  type='submit'
                  value=''
                  className='form-submit preloader'
                  disabled
                />
              ) : (
                <input
                  type='submit'
                  value='Reset Password'
                  className='form-submit'
                />
              )}
            </div>
          </Fragment>
        )}
      </form>
    </div>
  )
}

export default ResetPassword
