import { gql } from 'apollo-boost'

// User Mutations
export const SEND_CODE = gql`
  mutation SendCode(
    $name: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
  ) {
    sendCode(
      name: $name
      email: $email
      password: $password
      confirmPassword: $confirmPassword
    )
  }
`

export const REGISTER_USER = gql`
  mutation RegisterUser($emailCode: String!) {
    registerUser(emailCode: $emailCode) {
      token
      user {
        name
        email
        admin
      }
    }
  }
`

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      user {
        name
        email
        admin
      }
    }
  }
`

// Home Mutations
export const REGISTER_HOME = gql`
  mutation RegisterHome(
    $communityName: String!
    $street: String!
    $city: String!
    $zip: String!
    $phone: String!
    $certificateNumber: String!
    $closingDate: String!
    $agreeToTerms: String!
  ) {
    registerHome(
      communityName: $communityName
      street: $street
      city: $city
      zip: $zip
      phone: $phone
      certificateNumber: $certificateNumber
      closingDate: $closingDate
      agreeToTerms: $agreeToTerms
    ) {
      communityName
    }
  }
`

export const NEW_CLAIM = gql`
  mutation NewClaim(
    $relatedHomeId: ID!
    $claimStart: String!
    $claimEnd: String!
    $files: Upload!
    $kWhEntries: JSONObject
  ) {
    newClaim(
      relatedHomeId: $relatedHomeId
      claimStart: $claimStart
      claimEnd: $claimEnd
      files: $files
      kWhEntries: $kWhEntries
    ) {
      relatedHomeId
      submittedBy
      claimStart
      claimEnd
      kWhEntries
    }
  }
`

export const SEND_MESSAGE = gql`
  mutation SendMessage($name: String!, $email: String!, $message: String!) {
    sendMessage(name: $name, email: $email, message: $message) {
      name
      email
      message
    }
  }
`

export const SEND_PASSWORD_LINK = gql`
  mutation SendPasswordLink($email: String!) {
    sendPasswordLink(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $resetId: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetId: $resetId
      password: $password
      confirmPassword: $confirmPassword
    )
  }
`

export const STORE_CONTENT = gql`
  mutation StoreContent($name: ID!, $rawContent: JSONObject!) {
    storeContent(name: $name, rawContent: $rawContent)
  }
`
