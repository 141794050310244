import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({ msg, type }) => {
  return (
    <div
      style={{
        color: type === 'error' ? '#dc3545' : '#28a745',
        display: 'flex',
        justifyContent: 'center',
        padding: '.5rem',
        textAlign: 'center'
      }}
    >
      {msg.includes('Error: ') ? msg.replace('Error: ', '') : msg}
    </div>
  )
}

Alert.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Alert
