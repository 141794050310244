import React, { useState, useEffect, useContext, Fragment } from 'react';
import ContentEditor from '../../layout/content-editor/ContentEditor';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { STORE_CONTENT } from '../../../apollo/mutations';
import { GET_CONTENT } from '../../../apollo/queries';
import Context from '../../../context/context';
import Loader from '../../../images/loader.svg';

const Faq = () => {
  const context = useContext(Context);
  const { user } = context;

  const { data, error, loading, refetch } = useQuery(GET_CONTENT, {
    variables: { name: 'faq' }
  });

  const [
    storeContent,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(STORE_CONTENT);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    if (data && !error && !loading) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(data.getContent))
      );
    }

    // eslint-disable-next-line
  }, [data, error, loading]);

  const save = content => {
    storeContent({
      variables: { name: 'faq', rawContent: content }
    });
  };

  if (loading)
    return (
      <div className='container'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem'
          }}
        >
          <img src={Loader} alt='Loading...' width='50' />
        </div>
      </div>
    );

  return (
    <div className='container'>
      <h1 className='content-header'>Frequently Asked Questions</h1>
      <ContentEditor
        editorState={editorState}
        setEditorState={setEditorState}
        showEditor={showEditor}
      />
      {showEditor && (
        <Fragment>
          {mutationData && !mutationLoading && !mutationError && (
            <p
              style={{ color: '#28a745', textAlign: 'center', padding: '1rem' }}
            >
              Changes saved.
            </p>
          )}
          <div className='modify-text-container'>
            {mutationLoading ? (
              <button className='modify-text-btn save' disabled>
                <img src={Loader} alt='Loading...' width='20' />
              </button>
            ) : (
              <button
                className='modify-text-btn save'
                onClick={() =>
                  save(convertToRaw(editorState.getCurrentContent()))
                }
              >
                Saves Changes
              </button>
            )}
            <button
              className='modify-text-btn cancel'
              onClick={() => {
                setShowEditor(false);
                refetch();
              }}
            >
              Return
            </button>
          </div>
        </Fragment>
      )}
      {user && user.admin && !showEditor && (
        <div className='modify-text-container'>
          <button
            className='modify-text-btn edit'
            onClick={() => setShowEditor(true)}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default Faq;
