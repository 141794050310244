import React from 'react'
import PropTypes from 'prop-types'

const KWhEntry = ({
  monthNum,
  kWhNum,
  galNum,
  months,
  kWhEntries,
  claimInfo,
  setClaimInfo
}) => {
  const manageInput = e => {
    setClaimInfo({
      ...claimInfo,
      kWhEntries: {
        ...kWhEntries,
        [e.target.name]: e.target.value
      }
    })
  }

  return (
    <div className='form-item select'>
      <select
        name={monthNum}
        className='month-input'
        value={kWhEntries[monthNum]}
        onChange={manageInput}
        disabled
      >
        <option value=''>--Select Month--</option>
        {months.map(month => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
      <input
        type='text'
        className='kwh-input'
        placeholder='kWh'
        name={kWhNum}
        value={kWhEntries.kWhNum}
        onChange={manageInput}
      />
      <input
        type='text'
        className='gal-input'
        placeholder='Gallons'
        name={galNum}
        value={kWhEntries.galNum}
        onChange={manageInput}
      />
    </div>
  )
}

KWhEntry.propTypes = {
  monthNum: PropTypes.string.isRequired,
  kWhNum: PropTypes.string.isRequired,
  months: PropTypes.array.isRequired,
  kWhEntries: PropTypes.object.isRequired,
  claimInfo: PropTypes.object.isRequired,
  setClaimInfo: PropTypes.func.isRequired
}

export default KWhEntry
