import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_USER } from '../../../apollo/mutations';
import Alert from '../../layout/Alert';
import Context from '../../../context/context';

const Login = () => {
  const context = useContext(Context);
  const { authSuccess, authError } = context;

  const [loginUser, { data, loading, error }] = useMutation(LOGIN_USER);

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: ''
  });

  const { email, password } = loginInfo;

  const manageInput = e => {
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value
    });
  };

  const manageSubmit = e => {
    e.preventDefault();

    loginUser({ variables: { email, password } });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      localStorage.setItem('token', data.loginUser.token);

      authSuccess(data.loginUser);
    }

    if (!data && !loading && error) authError(error.graphQLErrors);
    // eslint-disable-next-line
  }, [data, error, loading]);

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Login</h1>
        {error &&
          !loading &&
          !data &&
          error.graphQLErrors.map((err, index) => (
            <Alert key={index} msg={err.message} type={'error'} />
          ))}
        <div className='form-item'>
          <input
            type='email'
            name='email'
            placeholder='Email'
            className='form-input'
            value={email}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='password'
            name='password'
            placeholder='Password'
            className='form-input'
            value={password}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <NavLink exact to='/forgot-password' className='login-link'>
            Forgot Password? Click here.
          </NavLink>
        </div>
        <div className='form-item'>
          {!loading ? (
            <input type='submit' value='Login' className='form-submit' />
          ) : (
            <input
              type='submit'
              value=''
              className='form-submit preloader'
              disabled
            />
          )}
        </div>
        <div className='form-item'>
          <p style={{ textAlign: 'center' }}>Don't have an account?</p>
        </div>
        <div className='form-item' style={{ marginBottom: '2.5rem' }}>
          <NavLink exact to='/register' className='register-link'>
            Click here to register.
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export default Login;
