import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { SEND_CODE } from '../../../apollo/mutations';
import Alert from '../../layout/Alert';
import context from '../../../context/context';

const Register = () => {
  const Context = useContext(context);
  const { codeSuccess, codeError } = Context;

  const [sendCode, { data, loading, error }] = useMutation(SEND_CODE);

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const { name, email, password, confirmPassword } = userInfo;

  const manageInput = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const manageSubmit = e => {
    e.preventDefault();

    sendCode({
      variables: { name, email, password, confirmPassword }
    });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      localStorage.setItem('token', data.sendCode);

      codeSuccess();
    }

    if (error && !loading && !data) codeError(error.graphQLErrors);
    //eslint-disable-next-line
  }, [data, loading, error]);

  return (
    <div className='container'>
      <form className='form' onSubmit={manageSubmit}>
        <h1 className='form-header'>Register</h1>
        {error &&
          !loading &&
          !data &&
          error.graphQLErrors.map((err, index) => (
            <Alert key={index} msg={err.message} type={'error'} />
          ))}
        <div className='form-item'>
          <input
            type='text'
            name='name'
            className='form-input'
            placeholder='Name'
            value={name}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='email'
            name='email'
            className='form-input'
            placeholder='Email'
            value={email}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='password'
            name='password'
            className='form-input'
            placeholder='Password'
            value={password}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          <input
            type='password'
            name='confirmPassword'
            className='form-input'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={manageInput}
          />
        </div>
        <div className='form-item'>
          {!loading ? (
            <input type='submit' value='Register' className='form-submit' />
          ) : (
            <input
              type='submit'
              value=''
              className='form-submit preloader'
              disabled
            />
          )}
        </div>
        <div className='form-item'>
          <p>Already have an account?</p>
        </div>
        <div className='form-item' style={{ marginBottom: '.5rem' }}>
          <NavLink exact to='/login' className='login-link'>
            Click here to login.
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export default Register;
