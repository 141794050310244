import React, { Fragment, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../images/efficiency_promise_logo.jpg';
import MobileNav from './MobileNav';
import Context from '../../../context/context';

const NavBar = () => {
  const context = useContext(Context);
  const { authorized, logout } = context;

  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <nav className='nav'>
      <div className='nav-logo-wrapper'>
        <img alt='Efficiency Promise Logo' src={Logo} width='150'></img>
      </div>
      <div className='nav-bars-wrapper'>
        {!showMobileNav ? (
          <button onClick={() => setShowMobileNav(true)}>
            <i className='fas fa-bars'></i>
          </button>
        ) : (
          <button onClick={() => setShowMobileNav(false)}>
            <i className='fas fa-times'></i>
          </button>
        )}
      </div>
      <ul className='nav-list'>
        <li className='nav-item'>
          <NavLink exact to='/' activeClassName='active' className='nav-link'>
            HOME
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink exact to='/faq' activeClassName='active' className='nav-link'>
            FAQ
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink exact to='/contact' activeClassName='active' className='nav-link'>
            CONTACT
          </NavLink>
        </li>
        {authorized ? (
          <Fragment>
            <li className='nav-item'>
              <NavLink to='/claim' activeClassName='active' className='nav-link'>
                CLAIM
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink exact to='/login' className='nav-link' onClick={logout}>
                LOGOUT
              </NavLink>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className='nav-item'>
              <NavLink exact to='/register' activeClassName='active' className='nav-link'>
                REGISTER
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink exact to='/login' activeClassName='active' className='nav-link'>
                LOGIN
              </NavLink>
            </li>
          </Fragment>
        )}
      </ul>
      {showMobileNav && <MobileNav setShowMobileNav={setShowMobileNav} authorized={authorized} logout={logout} />}
    </nav>
  );
};

export default NavBar;
