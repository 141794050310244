import React from 'react'
import StyleButton from './StyleButton'

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' }
]

const InlineStyle = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <div className='style-btn-container bottom'>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          active={currentStyle.has(type.style)}
        />
      ))}
    </div>
  )
}

export default InlineStyle
